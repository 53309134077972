import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
          <div class="footer">
            <ul>
                <li>
                    YOUTUBEDOWNLOADMP3 © 2021. All rights reserved. 
                </li>

                <li>
                <Link to="/">
                    Downloadmp3
                </Link>
                </li>
                
                <li>
                <Link to="/terms">
                    Terms
                </Link>
                </li>

                <li>
                <Link to="/help">
                    Help
                </Link>
                </li>
        
            </ul>
          </div>
    )
  }

  export default Footer;