import './YoutubeDownloader.css';
import { useState } from "react";
import SearchComponent from "./SearchComponent.js"

function HowToDownload() {
  const lines = ["Open Youtube and copy the Youtube video URL you want to download.",
                 "Paste the Youtube video URL in the Search box, Tool will fetch video info.",
                 "Click the \"Download\" button.",
                 "After the conversion is successfully completed, click the \"Download mp3\" button.",
                 "Once the video is downloaded, you can play it whenever and wherever you want."];

 const listItems = lines.map((number) =>
                 <li>{number}</li>
               );

  return (
    <div class="howto">
      <label>How to Download Youtube videos?</label>
      <ol>
         {listItems}
      </ol>
    </div>
  )
}

function ErrorUI() {
  return (
    <div class="error-ui">
    <label class="label-error">Invalid url! Please try again!</label>
    </div>
  )
}

function YoutubeDownloader() {  
  const [showErrorUI, setShowErrorUI] = useState(false);

  if (showErrorUI) {
    return (
    <>
      <SearchComponent setShowErrorUI={setShowErrorUI}/>
      <ErrorUI/>
      <HowToDownload/>
    </>
    )
  } else {
    return (
      <>
      <SearchComponent setShowErrorUI={setShowErrorUI}/>
      <HowToDownload/>
    </>
    )
  }
}

// https://ro.savefrom.net/1-cum-să-descărcați-videoclipuri-youtube.html
export default YoutubeDownloader;
