import './NavBar.css'
import { Link } from 'react-router-dom';

function NavBar() {
    return (
    <nav>
        <ul>
            <li>
                <Link to="/">Download mp3</Link>
            </li>
            <li>
                <Link to="/help">Help</Link>
            </li>
        </ul>
    </nav>
    )
}

/*
The reason Yt5s is the best youtube downloader
 

Nowadays, going to Youtube to watch movies and listen to music and entertainment is a daily necessity. of each person. Therefore, the need to download favorite movies or songs to your computer or on mobile devices to store and watch offline when not connected to 5G network or wifi is increasingly popular.

However, finding a software that allows to download youtube video quickly, easy and easy to use is not easy. Capturing the needs of users we have released youtube video downloader online YT5s.com.

What is YT5s.com?
Is a tool that allows you to quickly download videos from youtube to your computer or to download youtube videos on your phone . Yt5s has outstanding features and advantages like the top youtube video downloader today: y2mate, y2meta, x2convert ...

- The tool download youtube video online without installing any software.
- This tool allows downloading youtube on all platforms of computers, phones, tablets .. .
- A global tool that supports all popular languages.
- Processing speed and fastest youtube download up to 1GB/s.
- A free youtube downloader.

https://yt5s.com/en7/youtube-to-mp3
*/

// https://www.go-mp3.com/en25/terms
// https://www.go-mp3.com/en25/faq
export default NavBar;