import './Help.css'

function Help() {
    return (
      <div class="help-body">
      <h1>Help</h1>
        <div>
          <p>
          The reason YoutubeDownloadMp3.net is the best youtube downloader
          </p><br/>
          Nowadays, going to Youtube to watch movies and listen to music and entertainment is a daily necessity. of each person. Therefore, the need to download favorite movies or songs to your computer or on mobile devices to store and watch offline when not connected to 5G network or wifi is increasingly popular.
          
          <p>
          However, finding a software that allows to download youtube video quickly, easy and easy to use is not easy. Capturing the needs of users we have released youtube video downloader online YT5s.com.
          </p><br/>
          <p>
          What is YoutubeDownloadMp3.net?
          </p><br/>
  
          <p>
          Is a tool that allows you to quickly download videos from youtube to your computer or to download youtube videos on your phone . Yt5s has outstanding features and advantages like the top youtube video downloader today: y2mate, y2meta, x2convert ...
          </p><br/>
  
          <p>
          - The tool download youtube video online without installing any software.
          </p>
  
          <p>
          - This tool allows downloading youtube on all platforms of computers, phones, tablets .. .
          </p>
  
          <p>
          - A global tool that supports all popular languages.
          </p>
  
          <p>
          - Processing speed and fastest youtube download.
          </p>
  
          <p>
          - A free youtube downloader.
          </p><br/>

          <h3>How to download an mp3 form a youtube video?</h3><br/>
          <p>
              To download an mp3 from a youtube video simply go to the main page and paste a valid youtube link. Keep in mind that the video must be public and you should have permission to download it. We do not assume responsability for how the tool might be used. After that press download and you will be redirected to a new page containing a Download mp3 button to download the actual mp3. Also, pressing back in the browser or just Convert Again will redirect to the initial Download Youtube mp3 page to start the process again.
          </p>
        </div>
      </div>
    )
  }

  export default Help;