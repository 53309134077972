import './SearchComponent.css';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const host = "http://localhost:8000/"

function SearchComponent({setShowErrorUI}) {
  const [value, setValue] = useState('');
  const [videoURL, setVideoURL] = useState(null);
  const [loadVideoURL, setLoadVideoURL] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  
  async function getVideoInfoFunc(videoURL) {
    console.log("videoURL="+videoURL);
    let isValid = true;

    if (videoURL == null) {
      return false;
    }

    if (videoURL.length == 0) {
      return false;
    }

    setLoadVideoURL(false);
    
    await fetch(host + "getVideoInfo?videoURL=" + encodeURIComponent(videoURL)).then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    }).then(function(data) {
    console.log("data="+JSON.stringify(data));

    if (data == null) {
      console.log("data is null");

      isValid = false;
      return false;
    }

    if (data.error != null) {
      console.log("data error is non null");

      isValid = false;
      return false;
    }

    setData(data);
    let newData = data;
    newData["url"] = videoURL;

    console.log("videoURL=" + videoURL);
    console.log("newData=" + newData["url"]);
    
    navigate('/result', {
      state: newData
    })
    }).catch(function(error) {
      isValid = false;
      return false;
    });
    console.log("Returning: "+isValid);
    return isValid;
  }

  async function downloadButtonPressed(videoURL) {
    try {
      let value = await getVideoInfoFunc(videoURL);
      console.log("WasThereAnError="+!value+" function "+ JSON.stringify(setShowErrorUI));
      if (value == false) {
        setShowErrorUI(true);
      }
    } catch(err) {
      console.log("Error" + err);
    }
  }

  return (
    <>
    <div class="label-title-div">
      <label><h1>Youtube mp3 downloader</h1></label>
    </div>

    <div class="form">
      <div class="form-element">
        <input value={value} placeholder="Paste youtube link here" onInput={e => setValue(e.target.value)}/>
        <div class="asd">
          <button onClick={() => { 
            setVideoURL({value});
            downloadButtonPressed(value);
          }}>
          Download
          </button>
        </div>
      </div>
    </div>

    </>
  )
}

export default SearchComponent;