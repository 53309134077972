import './VideoData.css';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const host = "http://localhost:8000/"

function VideoData(props) {
  let location = useLocation(); 
  let response = location.state.response;
  let videoURL = location.state.url;
  let navigate = useNavigate();

  let imageSrc = response.videoDetails.thumbnails[response.videoDetails.thumbnails.length - 1];
  let title = response.videoDetails.title.substring(0, 100);
  let description = response.videoDetails.description.substring(0, 300);

  function createSelectedItems() {
    let formats = response.formats.filter(format => format != null);

    formats = formats.filter(function(obj) {
      return obj.mimeType.includes("audio/mp4");
    });

    function valueFromQuality(q) {
      if (q == "AUDIO_QUALITY_MEDIUM") {
        return 2;
      }
      if (q == "AUDIO_QUALITY_LOW") {
        return 1;
      }
      return 0;
    }

    formats.sort((a, b) => valueFromQuality(a.audioQuality) );
    let items = [];     
    let keys = [];    
    for (let i = 0; i < formats.length; i++) {     
      let container = formats[i].container;
      let qualityLabel = formats[i].qualityLabel
      /*(if (qualityLabel == null) {
        continue;
      }*/
      let value = container + " - " + qualityLabel;
      if (keys.includes(value)) {
        continue;
      }

      let item = {"url" : formats[i].url, 
                 "index" : items.length, 
                 "label": value,
                 "itag" : formats[i].itag};
      items.push(item);
      keys.push(value);
    }
    return items;
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  
  function downloadURL(item) {
    let videoURLEncoded = encodeURIComponent(videoURL);
    let itag = item.itag;
    let filename = response.videoDetails.title.replace(/\s{1,}/,"-");
    filename = filename.replace(/[^a-z0-9]/gi, ' ');
    filename = filename.replace(/\s+/g, ' ').trim();
    filename = filename + ".mp3";
    return host + "download?itag="+itag+"&filename="+filename+"&videoURL="+videoURLEncoded;
  }

  function downloadItem(item) {
    let url = downloadURL(item);
    fetch(url).then(function(response) {
      return response;
    }).then(function(data) {

    }).catch(function(error) {
      console.log("Something went wrong "+error);
    });
  }

  const [optionItems, setOptionItems] = useState(createSelectedItems());
  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    fontFamily: "Arial",
    width: "400px"
  }
  const divStyle = {
    paddingTop: "14px"
  }

  function convertAgain() {
    navigate("/")
  }

  return (
    <>
  <div class="video-data">
  <div class="data">
    <div class="thumbnail">
      <img src={imageSrc.url}></img>
    </div>
    <div class="info">
      <h2>{title}</h2>
      <p>
        {description}
      </p>
    </div>
  </div>
  <div class="controls">
    <a href={downloadURL(optionItems[selectedIndex])} download>
      <button>Download mp3</button>
    </a>
    <div style={divStyle}>
    <button class="convert-again" style={mystyle} onClick={convertAgain}>Convert again</button>
    </div>
  </div>
</div>

  </>
  )
}

export default VideoData;