import './Terms.css'

function Terms() {
  return (
    <>
    <div class="terms-body">
      <h1>Terms of Service</h1>
      <h1>Youtube mp3 download</h1>
      <h4>Last modified 15/09/2021.</h4>
      
      <h1 class="h1title">General</h1>
      <div>
        <p>
        BY USING OR VISITING THIS WEBSITE (together with all Content available through the youtubedownloadmp3.net domain name, the "Website"), OR SUBMITTING CONTENT TO THIS WEBSITE, YOU SIGNIFY YOUR ASSENT TO THESE TERMS AND CONDITIONS (the "Terms & Conditions"). These Terms & Conditions apply to all users of the Website. If you do not agree to these Terms & Conditions or the Privacy Policy, then please do not use the Website. IF YOU DO NOT UNDERSTAND THE ENGLISH LANGUAGE, then please use a translator or do not use the Website.
        </p>
        <p>
        The Website may contain links to third party websites that are not owned or controlled by youtubedownloadmp3.net. youtubedownloadmp3.net is not affiliated with those websites, has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites. In addition, youtubedownloadmp3.net will not and cannot censor or edit the content of any third-party site. By using the Website, you expressly release youtubedownloadmp3.net from any and all liability arising from your use of any third-party website. Accordingly, we encourage you to be aware when you have left the Website and to read the terms and conditions and privacy policy of each other website that you visit. youtubedownloadmp3.net does not host any of the videos embedded here.
        </p>

        <p>
        youtubedownloadmp3.net hereby grants you permission to use the Website, provided that: (i) your use of the Website is solely for your personal, noncommercial use; (ii) you will not copy, distribute or modify any part of the Website without youtubedownloadmp3.net's prior written authorization; (iii) you will not send unsolicited or unauthorized advertisements, spam, chain letters, etc., (iv) you will not transmit any Content which contains software viruses, or other harmful computer code, files or programs; (v) you will not disrupt servers or networks connected to the Website; and (vi) you comply with these Terms & Conditions. You are solely responsible for the activity that occurs on this Website. You agree not to use or launch any automated system, including without limitation, "robots," "spiders," and "offline readers," that accesses the Website in a manner that sends more request messages to its servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser. youtubedownloadmp3.net grants the operators of public search engines permission to use spiders to copy materials from the Website for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials. youtubedownloadmp3.net reserves the right to revoke these exceptions either generally or in specific cases. You agree not to collect or harvest any personally identifiable information, including account names or e-mail addresses, from the Website, nor to use the communication systems provided by the Website for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Website with respect to their User Submissions (as defined below). youtubedownloadmp3.net has the right to terminate your access to the Website, in its sole discretion, immediately and with or without cause.
        </p>
     </div>

     <h1 class="h1title">Copyright</h1>
      <div>
        The content on the Website, including all User Submissions, including without limitation, the text, software, scripts, graphics, photos, sounds, music, videos and interactive features ("Content") and the trademarks, service marks and logos contained therein ("Marks"), are owned by or licensed to youtubedownloadmp3.net. Content on the Website is provided to you "AS IS" for your information and personal use only and may not be used, copied, distributed, transmitted, broadcast, displayed, sold, licensed, reverse engineered, de-compiled, or otherwise exploited for any other purposes whatsoever without youtubedownloadmp3.net's prior written consent. youtubedownloadmp3.net reserves all rights not expressly granted in and to the Website. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein. You agree not to circumvent, disable or otherwise interfere with security-related features of the Website or features that prevent or restrict use or copying of any Content or enforce limitations on use of the Website.

        youtubedownloadmp3.net respects the intellectual property rights of others and requests you to do the same. youtubedownloadmp3.net does not permit infringement of intellectual property rights on its platform, and will promptly suspend commercial content (served via a publicly available web address / URL) from being able to be converted and downloaded by its platform when kindly notified. If you’re a content creator/owner, copyright owner or an agent thereof and would like to disable the possible use of youtubedownloadmp3.net’s platform to convert your publicly available content(s), please kindly send us a request via e-mail at copyright@youtubedownloadmp3.net with the following information: the URL(s) and description(s) of the content(s) you want us to block; a form of electronic or physical evidence showing that you have the rights to act for the content(s); contact information that is reasonably sufficient to permit us to contact you, such as an address, telephone number, and a valid e-mail address. The relevant content(s) will be blacklisted in our system within 24 hours.
     </div>
    
     <h1 class="h1title">Privacy</h1>
      <div>
      Our different processes do not require us to know your IP so we do not log and do not collect any IP addresses.

      A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.

      We use third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, e-mail address or telephone number) about your visits to this and other websites in order to provide advertisements on this site and other sites about goods and services that may be of interest to you.

      youtubedownloadmp3.net may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.

      youtubedownloadmp3.net respects the privacy of its users and does not collect any personal information about its users.
     </div>
    <div class="bottom-div"/>
    </div>
  </>
  )
}

export default Terms;