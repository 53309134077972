import './App.css';
import Terms from './Terms.js';
import Footer from './Footer';
import Help from './Help.js'
import YoutubeDownloader from './YoutubeDownloader.js';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import VideoData from './VideoData';
import { useEffect } from "react";
import NavBar from './NavBar';
import ReactGA from 'react-ga';

const DEFAULT_CONFIG = {
  trackingId: '286505273',
  debug: true,
  gaOptions: {
    cookieDomain: 'none'
  }
};

function Home() {
  return (
    <>
    <YoutubeDownloader/>
    </>
  )
}

function Result() {
  return (
    <>
    <VideoData/>
    </>
  )
}

function App() {
  useEffect(() => {
    document.title = "Youtube download mp3"
  }, [])
  return (
    <>
      <NavBar/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/result" element={<Result />}/>
          <Route path="/help" element={<Help />}/>
          <Route path="/terms" element={<Terms />}/>
        </Routes>
      <Footer/>
    </>
  );
}

export default App;